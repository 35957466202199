import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {base_url, new_base_url} from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { MovieSessions } from "../../interfaces";
import { auth } from "../Auth";
import {checkAvailableBuyTicket} from "../../helper/config-helper";

const Screenings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [movie, setMovie] = useState<[] | MovieSessions[]>([]);
    const date = location.pathname.split('/')[2];

    const fetchScreenings = useCallback((date: string) => {
        const dates: string[] = [];
        dates.push(date)
        axios({
            url: new_base_url() + `/get_conversation?` + new URLSearchParams({
                'selected_date': date
            }),
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setMovie(data.response);
            setIsLoading(false);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchScreenings(date);
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchScreenings(date);
    }, [fetchScreenings, date]);

    const handleNavigation = async (e: React.MouseEvent<HTMLElement>, movie: MovieSessions) => {
        e.preventDefault()
        const available = await checkAvailableBuyTicket()
        if(!available) return
        // @ts-ignore
        window.ticketonNew.openShow(movie.ticketonId)
    };

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <>
            {
                movie.length > 0 ?
                    movie.map(item => (
                        <div className="daily-seance-item" onClick={(e) => handleNavigation(e, item)} key={item.sessionId}>
                            <div className="daily-seance-item__seance-time">
                                {item.time}
                            </div>
                            <div className="daily-seance-item__seance-info">
                                <div className="daily-seance-item__seance-title">{item.filmName.replaceAll('kg', '').replaceAll('()', '').replaceAll('()', '')}</div>
                                <div className="daily-seance-item__genres-age-info">
                                    <span>{item.age}</span>
                                    <span>{item.genre}</span>
                                </div>
                                <div className="daily-seance-item__other-info">
                                    <span>{item.formatContent}</span>
                                    <span>
                                        {
                                            item.price
                                        } c
                                    </span>
                                    {/* <span> Студенческий -
                                {
                                    item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[1].sum ?
                                        item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[1].sum.sum / 100 : null
                                } c
                            </span>
                            <span> Детский -
                                {
                                    item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[0].sum ?
                                        item.getSessionPricesResponse.data.placesTypes.placeType.discounts.discount[0].sum.sum / 100 : null
                                } c
                            </span> */}
                                    <span>{item.hallName}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="no-seanses">
                        <p className="no-seanses__title">Сеансов на сегодня нет</p>
                    </div>
            }
        </>
    );
}

export default Screenings;