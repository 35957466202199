import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {base_url, new_base_url} from "../components/Base_url";
import Spinner from 'react-bootstrap/Spinner';
import {Movies, Premiere, Promo, Times} from "../interfaces";
import {auth} from './Auth';
import {checkAvailableBuyTicket} from "../helper/config-helper";
import {Simulate} from "react-dom/test-utils";
import abort = Simulate.abort;

export const Main = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [movie, setMovie] = useState<[] | Movies[]>([]);
    const [promo, setPromo] = useState<[] | Promo[]>([]);
    const [premiere, setPremiere] = useState<[] | Premiere[]>([]);
    const [sessionsLength, setSessionsLength] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    let date = location.pathname.split('/')[1];
    const [isModalVisible, setModalVisible] = useState(false);
    const [sessionId, setSessionId] = useState(0);

    let currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();

    if (date === "" || date === undefined) {
        date = day + "." + month + "." + year;
    }

    useEffect(() => {
        fetchSessionsLength();
        fetchPromo();
        fetchPremiere();
        fetchMovies(date);
    }, [location]);

    const fetchPromo = () => {
        axios({
            url: base_url() + "/api/v1/admin/getPromo",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setPromo(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchPromo();
            }
        });
    };

    const fetchPremiere = () => {
        axios({
            url: base_url() + "/api/v1/admin/getPremier",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setPremiere(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchPremiere();
            }
        });
    };

    const fetchMovies = (date: string) => {
        setIsLoading(true);
        const dates: string[] = [];
        dates.push(date)
        console.log(dates);
        axios({
            url: new_base_url() + `/get_films?` + new URLSearchParams({
                'selected_date': date
            }),
            method: "GET",
        }).then((response) => {

            if (response.data.status === 0) {
                const data = response.data;
                // @ts-ignore
                const values = data.response.map((item) => {
                    return item.times
                }).flat()
                if (values.length > 0) {
                    setMovie(data.response);
                } else {
                    const today = new Date();

                    const dateParts = date.split('.');
                    // @ts-ignore
                    const inputDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    if (inputDate.toDateString() === today.toDateString()) {
                        inputDate.setDate(inputDate.getDate() + 1);
                        const newDateString = inputDate.toLocaleDateString('ru-RU');
                        window.location.href = `/${newDateString}`
                    }
                }
                setIsLoading(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchMovies(date);
            }
        });
    };

    const fetchSessionsLength = async () => {
        await axios({
            url: new_base_url() + "/get_dates",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json"
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setSessionsLength(data.response.length);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchSessionsLength()
            }
        });
    };

    const handleNavigation = async (e: React.MouseEvent<HTMLElement>, movie: Times) => {
        e.preventDefault()
        const available = await checkAvailableBuyTicket()
        if (!available) return
        // @ts-ignore
        window.ticketonNew.openShow(movie.ticketonId)
    };

    const handleMouseEnter = (id: number) => {
        setModalVisible(true);
        setSessionId(id);
    };

    const handleMouseLeave = (id: number) => {
        setModalVisible(false);
        setSessionId(id);
    };

    if (isLoading) {
        return (
            <div className="releases-list">
                <div className="container main-container">
                    <Spinner animation='grow' variant="success" className="spinner"/>
                </div>
            </div>
        );
    }

    return (
        <div className="releases-list">
            <div className="container main-container">
                {
                    sessionsLength > 0 && movie.length > 0 ?
                        movie.filter(item => item.times?.length > 0).map(item => (
                            <a key={item.filmId} className="releases-item" href={`/release/${date}/${item.filmId}`}>
                                <div className="releases-item__poster gradient_1">
                                    <div className="releases-item__poster-height"></div>
                                    <img className="releases-item__poster-img" alt={`Постер релиза ${item.filmName}`}
                                         src={item.picture ?
                                             `${item.picture}`
                                             : ""
                                         }
                                    />
                                    <div className="releases-item__age">{item.age}</div>
                                    <div className="releases-item__badge">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div className="releases-item__info">
                                    <div className="releases-item-description">
                                        <div
                                            className="releases-item-description__title">{item.filmName.replaceAll('kg', '').replaceAll('()', '').replaceAll('()', '')}</div>
                                        {item.genre ?
                                            <div className="releases-item-description__badge">
                                                <span>{item.genre}</span>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="releases-item-schedule">
                                        {item.times && item.times.length >= 1 ?
                                            <>
                                                {item.times.map(time => (
                                                    <div className="seance-item" key={time.sessionId}
                                                         onMouseEnter={() => handleMouseEnter(time.sessionId)}
                                                         onMouseLeave={() => handleMouseLeave(time.sessionId)}>
                                                        <div tabIndex={0} className="widget-overlay"
                                                             data-action="open-widget"
                                                             onClick={(e) => handleNavigation(e, time)}></div>
                                                        <div className="seance-item__container">
                                                            <div className="seance-item__item">
                                                                <div className="seance-item__time">{time.time}</div>
                                                                <div className="seance-item__wrapper">
                                                                    <div
                                                                        className={`seance-item__description`}>
                                                                        <span>{time.formatContent}</span>
                                                                        <span className="seance-item--space"></span>
                                                                        <span>
                                      {
                                          time.price
                                      } c
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seance-item__hall">{time.hallName}</div>
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <button className="seance-item time-tooltip">Сеансы на Завтра</button>
                                        }
                                    </div>
                                </div>
                            </a>
                        ))
                        :
                        <div className="no-seanses">
                            <p className="no-seanses__title">Сеансов на сегодня нет</p>
                        </div>
                }
            </div>
            {/* <div className="news-others releases-list--primary">
        <div className="container promo-container--others">
          <h2 className="promo__title-others promo__title-others">Акции и скидки</h2>
          <div className="promo__title-container">
            <a className="promo__title-link promo__title-link" href="/promo">
              <span>Показать все </span>
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" className="icon icon-white icon--arrow-right">
              <path fillRule="evenodd" d="M1.41.84L6 5.42 10.59.84 12 2.25l-6 6-6-6z"></path>
            </svg>
          </div>
        </div>
        <div className="promo-list">
          <div className="promo-list-container">
            {promo.map(item => (
              <a className="promo-item promo-item__others" href={`/promo/${item.id}`} key={item.id}>
                <div className="promo-item__poster">
                  <div className="promo-item__poster-height"></div>
                  <img src={`data:image/jpeg;base64,${item.img}`} className="promo-item__poster-img" alt="Постер акции" />
                </div>
                <div className="promo-item__info">
                  <div className="promo-item-description">
                    <div className="promo-item-description__title">{item.title}</div>
                    <div className="promo-item-description__period">{item.title}</div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div> */}
            <div id="releasesSoon" className="releases-soon">
                {
                    premiere.length > 0 ?
                        <>
                            <div className="container releases-soon--title">
                                <h2 className="releases-list__title">Скоро в кино</h2>
                            </div>
                            <div className="container releases-container">
                                {premiere.map(item => (
                                    <a className="releases-item releases-item_soon" href={`soon/${item.id}`}
                                       key={item.id}>
                                        <div className="releases-item__poster releases-item__poster_soon gradient_4">
                                            <div className="releases-item__poster-height"></div>
                                            <img src={`data:image/jpeg;base64,${item.img}`}
                                                 className="promo-item__poster-img"
                                                 alt={`Постер релиза ${item.filmName}`}/>
                                            <div className="releases-item__age">{item.age}+</div>
                                            <div className="releases-item__badge"></div>
                                        </div>
                                        <div className="releases-item__info">
                                            <div className="releases-item__date-wrapper">
                                                <span className="releases-item__date">с {item.period}</span>
                                            </div>
                                            <div className="releases-item-description">
                                                <div
                                                    className="releases-item-description__title releases-item-description__title_small">{item.filmName}</div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    );
}
